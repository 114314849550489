import buttonSearch from "@/components/common/buttonSearch.vue";
import editGoods from "@/views/admin/goods/editGoods/index.vue";

export default {
  components: {
    buttonSearch,
    editGoods,
  },
  data() {
    return {
      upSkyObj: {
        hasUpSku: false,
        upSkuTime: "",
      },
      uploadExcelLoding: "el-icon-upload", //加载状态
      uploadExcelAction: "",
      showUploadExcelFile: false, //显示上传excel文件模块
      showEditDiv: false,
      showEditCode: "",
      tableData: [],
      tabIndx: 0,
      tabBarrs: [
        {
          name: "全部",
          num: 0,
        },
        {
          name: "出售中", //订货销售的商品
          num: 0,
        },
        {
          name: "人工下架",
          num: 0,
        },
        {
          name: "未到新品", //曾经上线 库存大于0的
          num: 0,
        },
        {
          name: "待上线",
          num: 0,
        },
        {
          name: "自然下架",
          num: 0,
        },

        {
          name: "库存预警",
          num: 0,
        },
        {
          name: "系列待上线",
          num: 0,
        },
        {
          name: "预售商品",
          num: 0,
        },
      ],
      showSetDiv: 0, //是否显示高级搜索 或 显示导出参数选择
      searchform: {
        //查询
        Name: "",
        ItemNo: "",
        GtClsNo: "",
        InLang: "",
        Lxys: "",
        first_display_flag_time: "", //首次上架时间
        has_us_num: "", //是否有库存
      },
      options: [
        {
          value: "en",
          label: "国际仓",
        },
        {
          value: "cn",
          label: "国内仓",
        },
      ],
      // 是否有库存
      has_us_num_options: [
        { label: "无", value: 0 },
        { label: "有", value: 1 },
      ],
      goodsType: [], //商品类型
      cnGoodsList: "", //国内商品列表
      showCnGoodsText: false,
      canSubmitButon: true, //是否允许显示提交国际仓按钮
      multipleSelection: [],
      showUmAuditText: false,
      unAuditList: "",
      showbanwrap: false,
      dryData: [],
      countryArr: {
        "欧盟": [
          "DE",
          "FR",
          "IT",
          "ES",
          "PT",
          "NL",
          "BE",
          "LU",
          "IE",
          "AT",
          "HU",
          "PL",
          "CZ",
          "SK",
          "SI",
          "HR",
          "DK",
          "SE",
          "FI",
          "CY",
          "MT",
          "GR",
          "RO",
          "BG",
          "EE",
          "LV",
          "LT",
          "GB",
          "REU",
        ],
        "伊斯兰教": [
          "MY",
          "BN",
          "ID",
          "BD",
          "MV",
          "PK",
          "AFG",
          "KZ",
          "UZ",
          "TJ",
          "KGZ",
          "TKM",
          "AZ",
          "IR",
          "IQ",
          "KW",
          "SY",
          "TR",
          "JO",
          "BL",
          "SA",
          "BH",
          "QA",
          "ARE",
          "OM",
          "YE",
          "AF",
          "LBY",
          "TUN",
          "DZ",
          "MAR",
          "SDN",
          "MRT",
          "SO",
          "DJI",
          "COM",
          "SEN",
          "GMB",
          "GIN",
          "SLE",
          "MLI",
          "NER",
          "NGA",
          "TCD",
          "ALB",
          "BIH",
          "XKX",
        ],
        "海湾": ["SA", "KW", "BH", "QA", "ARE", "OM", "YE"],
        "东盟": ["VN", "LA", "KH", "TH", "MM", "PH", "MY", "SG", "BN", "ID"],
      },
      checkboxGroupSel: [],
    };
  },
  created() {
    // this.getGoodsType(); //改在后端处理，前端数据量大渲染太卡
    this.uploadExcelByUpdate();
    this.__getData();
    //window.addEventListener("keydown", this.handleKeyDown, true); //开启监听键盘按下事件
    this.dryData.options=[];
    this.$axios.get(`/a1/country`).then((res) => {
      let options=[];
      let resData=res.data.data;
      //this.$message.success(resData[1].code,)
      for (let i=0; i < resData.length; i++) {
        let newData={
          value: resData[i].code,
          label: resData[i].name,
        };
        options.push(newData);
      }
      this.dryData.options=options;
    });
  },
  computed: {
    AppHeader() {
      let token=window.sessionStorage.getItem("token");
      return {
        Authorization: JSON.parse(token),
      };
    },
  },
  methods: {
    //添加国内商品数据
    submitCnGoods() {
      this.canSubmitButon=false;
      let arr=this.cnGoodsList.split("\n");
      let submitArr=[];
      if (arr.length > 0) {
        for (let i in arr) {
          submitArr.push(arr[i]);
        }
        this.$axios
          .post("/a1/goods/addCnGoods", {
            sku: submitArr,
          })
          .then(() => {
            this.$message.success("添加完毕");
            this.canSubmitButon=true;
          });
      }
    },
    //获取商品类别
    // getGoodsType() {
    //   this.$axios.get("/a1/gt").then((res) => {
    //     let newGoodsType = [];
    //     let goodsType = res.data.data;
    //     for (let i = 0; i < goodsType.length; i++) {
    //       newGoodsType[goodsType[i].cls_no] = goodsType[i].cls_cn;
    //     }
    //     this.goodsType = newGoodsType;
    //   });
    // },
    //更新sku条码
    upSku() {
      this.upSkyObj.hasUpSku=true;
    },
    //更新sku提交
    upSkuSubmit() {
      this.$axios
        .post("/a1/goods/UpSku?time=" + this.upSkyObj.upSkuTime)
        .then(() => {
          this.$message.success("更新完成");
        });
    },
    //全量同步
    synGoods() {
      this.$axios.post("/a1/goods/syn").then(() => {
        this.$message.success("商品正在同步中，同步时间需要2-3分钟");
      });
    },
    //每页显示数量事件
    handleSizeChange(val) {
      this.tableData[this.tabIndx].pageSize=val;
      this.showPageTypeData();
    },
    //翻页触发事件
    handleCurrentChange(val) {
      this.tableData[this.tabIndx].currentPage=val;
      this.showPageTypeData();
    },
    //根据当前标签ID刷新数据
    showPageTypeData() {
      //全部
      if (this.tabIndx === "0") this.getAllGoods();
      //出售中
      if (this.tabIndx === "1") this.getSellingGoods();
      //人工下架
      if (this.tabIndx === "2") this.getDisplayGoods();
      //未到新品
      if (this.tabIndx === "3") this.getHaltGoods();
      //待上线
      if (this.tabIndx === "4") this.getWaitingOnline();
      //自然下架
      if (this.tabIndx === "5") this.getOutOfStock();
      //库存预警
      if (this.tabIndx === "6") this.getWGoods();
      // 系列待上线
      if (this.tabIndx === "7") this.getWaitingOnlineSeries();
      // 预售
      if (this.tabIndx === "8") this.getPresellWaitingOnline();
      //回滚table顶部
      let currentTable="multipleTable_" + this.tabIndx;
      this.$refs[currentTable][0].bodyWrapper.scrollTop=0;
    },
    //刷新对应的页面
    reloadTab() {
      // this.showPageTypeData();
      for (let i=0; i < this.tabBarrs.length; i++) {
        if (i === 0) this.getAllGoods(); //全部
        if (i === 1) this.getSellingGoods(); //出售中
        if (i === 2) this.getDisplayGoods(); //未到新品
        if (i === 3) this.getHaltGoods(); //人工下架
        if (i === 4) this.getWaitingOnline(); //待上线
        if (i === 5) this.getOutOfStock(); //自然下架
        if (i === 6) this.getWGoods(); //库存预警
        if (i === 7) this.getWaitingOnlineSeries(); //系列待上线产品
        if (i === 8) this.getPresellWaitingOnline(); //预售待上线
      }
    },
    __getData() {
      this.tableData.search={
        list: [],
        Name: "", //对应的商品名字
        ItemNo: "", //条形编码
        GtClsNo: "", //类型ID
        InLang: "", //仓库
        has_show: "0", //是否过滤有库存，
        waiting_line: "0", //是否是待上线商品
        waiting_line_series: "0", //是否是系列待上线商品
        first_display_flag_time: [],
        has_us_num: -1, //是否有库存
      };
      for (let i=0; i < this.tabBarrs.length; i++) {
        this.tableData.push({
          currentPage: 1,
          pageSize: 10,
          total: 100,
        });
      }
      this.reloadTab();
    },
    //过滤数据
    filterData(goodsDataArr) {
      for (let i in goodsDataArr) {
        if (goodsDataArr[i].file_type_icon_list) {
          goodsDataArr[i].file_type_icon_list_arr=
            goodsDataArr[i].file_type_icon_list.split(",");
        } else {
          goodsDataArr[i].file_type_icon_list_arr=[];
        }
      }
      return goodsDataArr;
    },
    //获取全部商品
    getAllGoods() {
      let i=0;
      let requestData={
        page: this.tableData[i].currentPage,
        pageSize: this.tableData[i].pageSize,
        Name: this.tableData.search.Name, //商品名字
        ItemNo: this.tableData.search.ItemNo, //条形编码
        GtClsNo: this.tableData.search.GtClsNo, //类型ID
        InLang: this.tableData.search.InLang, //仓库
        Lxys: this.tableData.search.Lxys,
        first_display_flag_time: this.tableData.search.first_display_flag_time ? this.tableData.search.first_display_flag_time.join(",") : "",
      };
      this.$axios
        .get("/a1/goods", {
          params: requestData,
        })
        .then((response) => {
          const { data }=response.data;
          let page=data.page;
          let total=data.total;
          this.tableData[i].list=this.filterData(data.data);
          this.tableData[i].currentPage=page;
          this.tableData[i].total=total;
          this.tabBarrs[i].num=total;
          this.$forceUpdate();
        });
    },
    //出售中的产品
    getSellingGoods() {
      let i=1;
      let requestData={
        page: this.tableData[i].currentPage,
        pageSize: this.tableData[i].pageSize,
        Display: "y",
        //queryType : "",
        Name: this.tableData.search.Name, //商品名字
        ItemNo: this.tableData.search.ItemNo, //条形编码
        GtClsNo: this.tableData.search.GtClsNo, //类型ID
        InLang: this.tableData.search.InLang, //仓库
        has_show: "1",
        Lxys: this.tableData.search.Lxys,
        first_display_flag_time: this.tableData.search.first_display_flag_time ? this.tableData.search.first_display_flag_time.join(",") : "",
      };
      this.$axios
        .get("/a1/goods", {
          params: requestData,
        })
        .then((response) => {
          const { data }=response.data;
          let page=data.page;
          let total=data.total;
          this.tableData[i].list=this.filterData(data.data);
          this.tableData[i].currentPage=page;
          this.tableData[i].total=total;
          this.tabBarrs[i].num=total;
          this.$forceUpdate();
        });
    },
    //等待上线
    getWaitingOnline() {
      let i=4;
      let requestData={
        page: this.tableData[i].currentPage,
        pageSize: this.tableData[i].pageSize,
        Name: this.tableData.search.Name, //商品名字
        ItemNo: this.tableData.search.ItemNo, //条形编码
        GtClsNo: this.tableData.search.GtClsNo, //类型ID
        InLang: this.tableData.search.InLang, //仓库
        waiting_line: "1", //是否为待上线商品
        Lxys: this.tableData.search.Lxys,
        first_display_flag_time: this.tableData.search.first_display_flag_time ? this.tableData.search.first_display_flag_time.join(",") : "",
      };
      this.$axios
        .get("/a1/goods", {
          params: requestData,
        })
        .then((response) => {
          const { data }=response.data;
          let page=data.page;
          let total=data.total;
          this.tableData[i].list=this.filterData(data.data);
          this.tableData[i].currentPage=page;
          this.tableData[i].total=total;
          this.tabBarrs[i].num=total;
          this.$forceUpdate();
        });
    },

    // 获取系列待上线产品
    getWaitingOnlineSeries() {
      let i=7;
      let requestData={
        page: this.tableData[i].currentPage,
        pageSize: this.tableData[i].pageSize,
        Name: this.tableData.search.Name, //商品名字
        ItemNo: this.tableData.search.ItemNo, //条形编码
        GtClsNo: this.tableData.search.GtClsNo, //类型ID
        InLang: this.tableData.search.InLang, //仓库
        waiting_line_series: "1", //是否为系列待上线商品
        Lxys: this.tableData.search.Lxys,
        first_display_flag_time: this.tableData.search.first_display_flag_time ? this.tableData.search.first_display_flag_time.join(",") : "",
      };
      this.$axios
        .get("/a1/goods", {
          params: requestData,
        })
        .then((response) => {
          const { data }=response.data;
          let page=data.page;
          let total=data.total;
          this.tableData[i].list=this.filterData(data.data);
          this.tableData[i].currentPage=page;
          this.tableData[i].total=total;
          this.tabBarrs[i].num=total;
          this.$forceUpdate();
        });
    },
    //预售商品
    getPresellWaitingOnline() {
      let i=8;
      let requestData={
        page: this.tableData[i].currentPage,
        pageSize: this.tableData[i].pageSize,
        Name: this.tableData.search.Name, //商品名字
        ItemNo: this.tableData.search.ItemNo, //条形编码
        GtClsNo: this.tableData.search.GtClsNo, //类型ID
        InLang: this.tableData.search.InLang, //仓库
        presell_waiting_line: "1", //是否为预售商品
        Lxys: this.tableData.search.Lxys,
        first_display_flag_time: this.tableData.search.first_display_flag_time ? this.tableData.search.first_display_flag_time.join(",") : "",
      };
      this.$axios
        .get("/a1/goods", {
          params: requestData,
        })
        .then((response) => {
          const { data }=response.data;
          let page=data.page;
          let total=data.total;
          this.tableData[i].list=this.filterData(data.data);
          this.tableData[i].currentPage=page;
          this.tableData[i].total=total;
          this.tabBarrs[i].num=total;
          this.$forceUpdate();
        });
    },

    //自然下架
    getOutOfStock() {
      let i=5;
      let requestData={
        page: this.tableData[i].currentPage,
        pageSize: this.tableData[i].pageSize,
        //Display: "y",
        Name: this.tableData.search.Name, //商品名字
        ItemNo: this.tableData.search.ItemNo, //条形编码
        GtClsNo: this.tableData.search.GtClsNo, //类型ID
        InLang: this.tableData.search.InLang, //仓库
        oos: "1", //自然下架
        Lxys: this.tableData.search.Lxys,
        first_display_flag_time: this.tableData.search.first_display_flag_time ? this.tableData.search.first_display_flag_time.join(",") : "",
      };
      this.$axios
        .get("/a1/goods", {
          params: requestData,
        })
        .then((response) => {
          const { data }=response.data;
          let page=data.page;
          let total=data.total;
          this.tableData[i].list=this.filterData(data.data);
          this.tableData[i].currentPage=page;
          this.tableData[i].total=total;
          this.tabBarrs[i].num=total;
          this.$forceUpdate();
        });
    },

    //待上线新品
    getHaltGoods() {
      let i=3;
      let requestData={
        page: this.tableData[i].currentPage,
        pageSize: this.tableData[i].pageSize,
        Name: this.tableData.search.Name, //商品名字
        ItemNo: this.tableData.search.ItemNo, //条形编码
        GtClsNo: this.tableData.search.GtClsNo, //类型ID
        InLang: this.tableData.search.InLang, //仓库
        waiting_line_new: "1", //是否为待上线商品
        Lxys: this.tableData.search.Lxys,
        first_display_flag_time: this.tableData.search.first_display_flag_time ? this.tableData.search.first_display_flag_time.join(",") : "",
      };
      this.$axios
        .get("/a1/goods", {
          params: requestData,
        })
        .then((response) => {
          const { data }=response.data;
          let page=data.page;
          let total=data.total;
          this.tableData[i].list=this.filterData(data.data);
          this.tableData[i].currentPage=page;
          this.tableData[i].total=total;
          this.tabBarrs[i].num=total;
          this.$forceUpdate();
        });
    },

    //获取库存预警产品
    getWGoods() {
      let i=6;
      let requestData={
        page: this.tableData[i].currentPage,
        pageSize: this.tableData[i].pageSize,
        Sw: 30,
        Name: this.tableData.search.Name, //商品名字
        ItemNo: this.tableData.search.ItemNo, //条形编码
        GtClsNo: this.tableData.search.GtClsNo, //类型ID
        InLang: this.tableData.search.InLang, //仓库
        Lxys: this.tableData.search.Lxys,
        first_display_flag_time: this.tableData.search.first_display_flag_time ? this.tableData.search.first_display_flag_time.join(",") : "",
      };
      this.$axios
        .get("/a1/goods", {
          params: requestData,
        })
        .then((response) => {
          const { data }=response.data;
          let page=data.page;
          let total=data.total;
          this.tableData[i].list=this.filterData(data.data);
          this.tableData[i].currentPage=page;
          this.tableData[i].total=total;
          this.tabBarrs[i].num=total;
          this.$forceUpdate();
        });
    },
    // 确认导出数据
    confirmExportGoods() {
      var postData={};
      var tipString="";
      switch (this.tabIndx) {
        // 导出人工下架
        case "2":
          let i=2;
          postData={
            page: this.tableData[i].currentPage,
            pageSize: this.tableData[i].pageSize,
            Display: "handle",
            Name: this.tableData.search.Name, //商品名字
            ItemNo: this.tableData.search.ItemNo, //条形编码
            GtClsNo: this.tableData.search.GtClsNo, //类型ID
            InLang: this.tableData.search.InLang, //仓库
            first_display_flag_time: this.tableData.search.first_display_flag_time ? this.tableData.search.first_display_flag_time.join(",") : "",
            has_us_num: (this.tableData.search.has_us_num === "" || this.tableData.search.has_us_num === -1) ? -1 : parseInt(this.tableData.search.has_us_num),
          };
          tipString+="【" + this.tabBarrs[parseInt(this.tabIndx)].name + "/" + this.tabBarrs[parseInt(this.tabIndx)].num + "】";
          break;
      }
      if (confirm("确认导出" + tipString + "吗？")) {
        this.$axios.post("/a1/goods/creatExportGoodsManger", postData).then((res) => {
          let dowloadKey=res.data.data.to_key;
          //开始下载
          location.href=this.$root.DownUrl + "/c1/down/" + dowloadKey;
        });
      }
    },
    //人工下架
    getDisplayGoods() {
      let i=2;
      this.$axios.get("/a1/goods", {
        params: {
          page: this.tableData[i].currentPage,
          pageSize: this.tableData[i].pageSize,
          Display: "handle",
          Name: this.tableData.search.Name, //商品名字
          ItemNo: this.tableData.search.ItemNo, //条形编码
          GtClsNo: this.tableData.search.GtClsNo, //类型ID
          InLang: this.tableData.search.InLang, //仓库
          first_display_flag_time: this.tableData.search.first_display_flag_time ? this.tableData.search.first_display_flag_time.join(",") : "",
          has_us_num: (this.tableData.search.has_us_num === "" || this.tableData.search.has_us_num === -1) ? -1 : parseInt(this.tableData.search.has_us_num),
        },
      }).then(res => {
        const { data }=res.data;
        let page=data.page;
        let total=data.total;
        this.tableData[i].list=this.filterData(data.data);
        this.tableData[i].currentPage=page;
        this.tableData[i].total=total;
        this.tabBarrs[i].num=total;
        this.$forceUpdate();
      });
    },

    //选择器
    changeUp: function(callback, row, eventType) {
      if (eventType == "hot") {
        let resStr="";
        if (row.heat == 1) {
          resStr="y";
        } else {
          resStr="n";
        }
        this.$axios
          .put("/a1/goods/" + row.id, {
            heat: resStr,
          })
          .then(() => {
            this.$message.success("修改成功");
          });
      }
      if (eventType == "line") {
        let resStr="";
        if (row.display_flag == 1) {
          resStr="y";
        } else {
          resStr="n";
        }
        this.lineGoodsSet(row.id, resStr, row);
      }
      //设置国内 国际仓
      if (eventType == "in_lang") {
        let resStr="";
        if (row.in_lang == 1) {
          resStr="cn";
        } else {
          resStr="en";
        }
        this.$axios
          .put("/a1/goods/" + row.id, {
            in_lang: resStr,
          })
          .then(() => {
            this.$message.success("修改成功");
          });
      }
    },
    //设置上下架
    lineGoodsSet(id, setString, row) {
      this.$axios
        .put("/a1/goods/" + id, {
          display_flag: setString,
        })
        .then(() => {
          this.$message.success("修改成功");
        }).catch((err) => {
        row.display_flag=(setString === "y") ? 0 : 1;
        this.$message.error(err.response.data.message);
      });
    },
    //============上传excel更新商品信息 beign===============
    //上传更新商品信息
    uploadUpdateGoods() {
      this.showUploadExcelFile=true;
    },
    uploadExcelByUpdate() {
      this.uploadExcelAction=this.$root.DownUrl + "/a1/goods/upFieldExcel";
    },
    //下载上传更新商品的excel文件
    dowExcelFile() {
      let downUrl=this.$root.DownUrl;
      window.open(downUrl + "/static/excel/upFieldExcel.xlsx");
    },

    //============上传excel更新商品信息 end===============

    //批量上架
    UpGoods() {
      if (this.multipleSelection.length === 0) return false;
      for (let i=0; i < this.multipleSelection.length; i++) {
        let id=this.multipleSelection[i].id;
        this.lineGoodsSet(id, "y");
      }
      this.getSellingGoods();
      this.getHaltGoods();
      this.getWGoods();
    },
    //批量下架
    SoGoods() {
      if (this.multipleSelection.length === 0) return false;
      for (let i=0; i < this.multipleSelection.length; i++) {
        let id=this.multipleSelection[i].id;
        this.lineGoodsSet(id, "n");
      }
      this.getSellingGoods();
      this.getHaltGoods();
      this.getWGoods();
    },

    //列表多选项框
    handleSelectionChange(val) {
      this.multipleSelection=val;
    },
    handleClick(tab) {
      this.tabIndx=tab.index;
    },
    //关门搜索
    clearSearch() {
      this.searchform={
        Name: "",
        ItemNo: "",
        GtClsNo: "",
        InLang: "",
      };
      this.$refs.buttonSearchdDiv.closeSearch();
    },
    //搜索
    searchEvn(e) {
      if (typeof e === "string") {
        this.tableData.search.Name=e;
        this.reloadTab(); //刷新对应的表格数据
      } else {
        this.tableData.search.Name=this.searchform.Name;
        this.tableData.search.ItemNo=this.searchform.ItemNo;
        this.tableData.search.GtClsNo=this.searchform.GtClsNo;
        this.tableData.search.InLang=this.searchform.InLang;
        this.tableData.search.Lxys=this.searchform.Lxys;
        this.tableData.search.first_display_flag_time=this.searchform.first_display_flag_time;
        this.tableData.search.has_us_num=this.searchform.has_us_num;
        this.reloadTab(); //刷新对应的表格数据
        this.$message.success("高级搜索");
      }
    },
    //导出
    exportDataFun(val) {
      let strto="";
      if (val.length > 0) {
        for (let i=0; i < val.length; i++) {
          let theStr="";
          if (val[i] == "国内") {
            theStr="cn";
          }
          if (val[i] == "国际") {
            theStr="in";
          }
          if (i < val.length - 1) {
            strto+=theStr + ",";
          } else {
            strto+=theStr;
          }
        }
      }
      let dowUrl=this.$root.DownUrl;
      window.open(dowUrl + "/a1/goods/exportExcel?job=" + strto);
    },
    editGoods(row) {
      this.showEditCode=row.item_no;
      this.showEditDiv=true;
    },
    handleClose() {
      return false;
    },

    //================上传excel文件
    //上传成功时的
    uploadExectGoods(response, file, fileList) {
      this.uploadExcelLoding="el-icon-upload";
      this.$message.success("上传成功");
    },
    //上传中
    uploadExectIng(event, file, fileList) {
      this.uploadExcelLoding="el-icon-loading";
    },
    //长传失败
    uploadExcelError(err, file, fileList) {
      this.uploadExcelLoding="el-icon-upload";
      this.$message.success("上传失败");
    },
    submitUnAuditGoods() {
      this.canSubmitButon=false;
      let arr=this.unAuditList.split("\n");
      let submitArr=[];
      if (arr.length > 0) {
        for (let i in arr) {
          submitArr.push(arr[i].trim());
        }
        this.$axios
          .post("/a1/goods/unAudit", {
            sku: submitArr,
          })
          .then(() => {
            this.$message.success("添加完毕");
            this.canSubmitButon=true;
          }).catch(err => {
          this.$message.error(err.response.data.message);
          this.canSubmitButon=true;
        });
      }
    },

    // 批量操作
    handleBulkOperations(actType) {
      var data={};
      var successMsg="";
      var errorMsg="";
      var select_ids=[];
      if (this.multipleSelection.length < 1) {
        this.$notify.error({
          title: "失败",
          message: "请先选择需要操作的数据",
        });
        return false;
      }
      // 设置选中的IDS
      for (const key in this.multipleSelection) {
        select_ids.push(this.multipleSelection[key].id);
      }
      // 区分批量操作
      switch (actType) {
        // 设置热门
        case "heat":
          data={
            act_type: "heat",
            select_ids: select_ids,
          };
          successMsg="成功热门";
          errorMsg="失败热门";
          break;
        // 取消热门
        case "notheat":
          data={
            act_type: "notheat",
            select_ids: select_ids,
          };
          successMsg="批量取消热门 成功";
          errorMsg="批量取消热门 失败";
          break;
      }
      // 统一请求接口
      this.$axios
        .patch("/a1/goods/bulkheat", data)
        .then(() => {
          this.$message.success(successMsg);
          this.__getData();
        }).catch(err => {
        this.$message.error(errorMsg + err.response.data.message);
      });

    },
    /* 确认提交修改 */
    configmSubmit() {
      if (confirm("你确定修改预售配置?")) {
        this.handleSubmit();
      }
    },
    /* 提交表单 */
    handleSubmit() {
      // 整理配置数据
      let arr=[];
      this.dryData.skus.split("\n").forEach(item => {
        if (item) {
          arr.push(item.trim());
        }
      });
      let banStr=this.dryData.value.join(",");
      let postData={
        ban_area: banStr + "",
        ban_remark: this.dryData.ban_remark,
        skus: arr,
      };

      //修改参数
      this.$axios.post(`/a1/goods/banarea`, postData)
        .then((res) => {
          if (res.error === 1) {
            this.$message.error(res.message);
          } else {
            this.$message.success("修改成功");
            this.getData();
          }
        })
        .catch((resErr) => {
          this.$message.error("修改失败：" + resErr.response.data.message);
        });
    },
    changeCountry(val, name) {
      if (name === "全部") {
        if (val) {
          //选中状态
          for (let key in this.dryData.options) {
            let code=this.dryData.options[key].value;
            //不存在 则 增加
            if (!this.dryData.value.includes(code)) {
              this.dryData.value.push(code);
            }
          }
        } else {
          this.dryData.value=[];
        }
      }
      //其他特殊地区
      this.checkboxGroupSelFun(val, name);
    },
    //获取国家在选中keyId，以方便做删除
    getKeyId(str, forArr) {
      let keyId=0;
      if (forArr.length > 0) {
        for (let key in forArr) {
          if (forArr[key] === str) {
            keyId=key;
          }
        }
      }
      return keyId;
    },
    //快速选择
    checkboxGroupSelFun(ok, name) {
      if (ok) {
        //选中状态
        for (let key in this.countryArr[name]) {
          let code=this.countryArr[name][key];
          //不存在 则 增加
          if (!this.dryData.value.includes(code)) {
            this.dryData.value.push(code);
          }
        }
      } else {
        //非选中状态
        for (let key in this.countryArr[name]) {
          let code=this.countryArr[name][key];
          //存在 则 删除
          if (this.dryData.value.includes(code)) {
            let delKey=this.getKeyId(code, this.dryData.value);
            this.dryData.value.splice(delKey, 1);
          }
        }
      }
    },
  },
};
