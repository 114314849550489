<template>
  <div style="margin: 20px 20px 20px 20px">
    <el-tabs v-model="tabIndx" @tab-click="handleClick">
      <buttonSearch
        ref="buttonSearchdDiv"
        placeholder="编码或名称"
        @search="searchEvn"
        @expGoods="exportDataFun"
        class="buttonSearchDiv"
      >
        <template #right>
          <div style="margin-bottom: 6px">
            <el-button type="primary" size="mini" @click="synGoods">同步</el-button>
            <el-button type="success" size="mini" @click="upSku">更新条码</el-button>
            <el-button size="mini" @click="UpGoods">上架</el-button>
            <el-button size="mini" @click="SoGoods">下架</el-button>
            <el-button
              size="mini"
              @click="uploadUpdateGoods"
              v-show="showUploadExcelFile === false"
            >上传更新
            </el-button>

            <el-button
              v-if="tabIndx === '4'"
              size="mini"
              type="danger"
              v-show="showCnGoodsText === false"
              @click="showCnGoodsText = true"
            >
              添加国内仓商品
            </el-button>
            <el-button
              v-if="tabIndx === '0'"
              size="mini"
              type="danger"
              v-show="showUmAuditText === false"
              @click="showUmAuditText = true"
            >
              添加未审核商品
            </el-button>
            <el-dropdown>
              <el-button
                type="primary"
                size="mini"
                style="margin-left: 10px;margin-right: 10px;"
              >
                批量<i class="el-icon-arrow-down el-icon--right" />
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <div class="table_btn">
                  <div>
                    <el-dropdown-item>
                      <el-button
                        type="primary"
                        size="mini"
                        plain
                        @click="handleBulkOperations('heat')"
                      >
                        设置热门
                      </el-button>
                    </el-dropdown-item>
                  </div>
                  <div>
                    <el-dropdown-item>
                      <el-button
                        type="primary"
                        size="mini"
                        plain
                        style="margin-top: 5px;"
                        @click="handleBulkOperations('notheat')"
                      >
                        取消热门
                      </el-button>
                    </el-dropdown-item>
                  </div>

                </div>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button
              size="mini"
              type="primary"
              @click="showbanwrap = true"
            >
              批量设置禁配
            </el-button>
          </div>
        </template>

        <template #left>
          <el-form
            :inline="true"
            ref="form"
            :model="searchform"
            label-width="40px"
            class="form-wrap"
          >
            <el-form-item label="名称" class="form-item-wrap">
              <el-input
                v-model="searchform.Name"
                size="mini"
                clearable
                style="width: 170px"
                @keyup.native.enter="searchEvn"
              ></el-input>
            </el-form-item>
            <el-form-item label="条码" class="form-item-wrap">
              <el-input
                v-model="searchform.ItemNo"
                size="mini"
                clearable
                style="width: 170px"
                @keyup.native.enter="searchEvn"
              ></el-input>
            </el-form-item>
            <el-form-item label="类别" class="form-item-wrap">
              <el-input
                v-model="searchform.GtClsNo"
                size="mini"
                clearable
                style="width: 120px"
                @keyup.native.enter="searchEvn"
              ></el-input>
            </el-form-item>
            <el-form-item label="仓库" class="form-item-wrap">
              <!-- <el-input v-model="searchform.warehouse" size="mini" clearable></el-input> -->
              <el-select
                v-model="searchform.InLang"
                placeholder="请选择"
                size="mini"
                clearable
                style="width: 120px"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="流行" class="form-item-wrap">
              <el-input v-model="searchform.Lxys" size="mini" style="width:120px;" clearable></el-input>
            </el-form-item>

            <!-- 首次上架时间 -->
            <el-form-item label="首次上架时间">
              <el-date-picker
                v-model="searchform.first_display_flag_time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                >
              </el-date-picker>
            </el-form-item>
            <!--  -->
            <el-form-item v-if="tabIndx == 2" label="是否有库存" class="form-item-wrap">
              <el-select
                v-model="searchform.has_us_num"
                placeholder="请选择"
                size="mini"
                clearable
                style="width: 120px"
              >
                <el-option
                  v-for="item in has_us_num_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item class="form-item-wrap" style="margin-bottom: 6px">
              <el-button type="primary" size="mini" @click="searchEvn">搜索</el-button>
              <el-button type="danger" size="mini" @click="confirmExportGoods" v-if="parseInt(tabIndx)===2" >导出</el-button>
            </el-form-item>
          </el-form>
        </template>
      </buttonSearch>

      <el-tab-pane
        v-for="(item, index) in tabBarrs"
        :key="index"
        :label="`${item.name} (${item.num})`"
      >
        <div class="cnGoodsText" v-show="showCnGoodsText">
          <p class="cnGoodsText_title">国内产品调仓</p>
          <div style="margin: 10px">
            <table>
              <tr>
                <td><textarea rows="5" v-model="cnGoodsList"></textarea></td>
                <td valign="bottom">
                  <div style="margin-left: 10px; color: grey; font-size: 12px">
                    例如：<br />
                    6932284842202<br />
                    6932284842769
                  </div>
                </td>
              </tr>
            </table>
            <el-button
              type="danger"
              v-show="canSubmitButon === true"
              @click="submitCnGoods"
              size="mini"
            >提交
            </el-button>
            <el-button
              type="danger"
              v-show="canSubmitButon === false"
              size="mini"
              icon="el-icon-loading"
            >执行中
            </el-button>
            <el-button
              type="success"
              @click="showCnGoodsText = false"
              size="mini"
            >关闭
            </el-button>
          </div>
        </div>
        <div class="cnGoodsText" v-show="showUmAuditText">
          <p class="cnGoodsText_title">未审核商品条码</p>
          <div style="margin: 10px">
            <table>
              <tr>
                <td><textarea rows="5" v-model="unAuditList"></textarea></td>
                <td valign="bottom">
                  <div style="margin-left: 10px; color: grey; font-size: 12px">
                    例如：<br />
                    6932284842202<br />
                    6932284842769
                  </div>
                </td>
              </tr>
            </table>
            <el-button
              type="danger"
              v-show="canSubmitButon === true"
              @click="submitUnAuditGoods"
              size="mini"
            >提交
            </el-button>
            <el-button
              type="danger"
              v-show="canSubmitButon === false"
              size="mini"
              icon="el-icon-loading"
            >执行中
            </el-button>
            <el-button
              type="success"
              @click="showUmAuditText = false"
              size="mini"
            >关闭
            </el-button>
          </div>
        </div>

        <div v-show="showUploadExcelFile" style="margin-top: 5px">
          <el-card class="box-card">
            <div
              slot="header"
              class="clearfix"
              style="margin: -15px; padding-top: 5px"
            >
              <span style="font-size: 14px">上传更新产品信息</span>
              <el-button
                style="float: right"
                type="text"
                @click="showUploadExcelFile = false"
              >关闭
              </el-button>
            </div>
            <div class="text item">
              <ul>
                <li
                  style="border-bottom: 1px solid #c0c0c0; margin-bottom: 10px">
                  <a href="#" class="dowExcelFile" @click="dowExcelFile">下载上传模板</a>
                </li>
                <li>
                  <el-upload
                    :action="uploadExcelAction"
                    :headers="AppHeader"
                    name="excel"
                    :show-file-list="false"
                    :on-progress="uploadExectIng"
                    :on-success="uploadExectGoods"
                    :on-error="uploadExcelError"
                  >
                    <el-button
                      size="small"
                      type="primary"
                      :icon="uploadExcelLoding"
                    >点击上传
                    </el-button>
                    <div slot="tip" class="el-upload__tip">
                      只能上传excel文件
                    </div>
                  </el-upload>
                </li>
              </ul>
            </div>
          </el-card>
        </div>

        <div style="margin: 10px 0 0 0">
          <el-table
            border
            :ref="'multipleTable_' + index"
            :data="tableData[index].list"
            tooltip-effect="dark"
            style="width: 100%; z-index: 99"
            @selection-change="handleSelectionChange"
            height="630"
          >
            <el-table-column type="selection" align="center" width="55">
            </el-table-column>
            <el-table-column
              prop="id"
              label="ID"
              align="center"
              width="80"
            ></el-table-column>

            <el-table-column label="名称" align="center" width="300">
              <template slot-scope="scope">
                <div class="media" style="text-align: left">
                  <viewer>
                    <img
                      height="60"
                      :src="`http://hwimg.xmvogue.com/thumb/${scope.row.item_no}.jpg?x-oss-process=style/800`"
                      class="mr-3"
                      alt="..."
                    />
                  </viewer>
                  <div class="media-body">
                    <h6 class="mt-0" style="font-size: 14px">
                      {{ scope.row.item_name }}
                    </h6>
                    <p>{{ scope.row.item_en }}</p>
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="item_no"
              align="center"
              label="货号"
              width="130"
            >
            </el-table-column>

            <el-table-column
              prop="xm_lxys"
              align="center"
              label="流行元素"
              width="130"
            >
            </el-table-column>

            <el-table-column
              prop="item_no"
              align="center"
              label="认证文件"
              width="150"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.file_type_icon_list_arr.length > 0">
                  <img
                    width="30"
                    :src="item"
                    v-for="(item, index) in scope.row.file_type_icon_list_arr"
                    :key="index"
                    alt=""
                  />
                </div>
                <div v-else>无认证</div>
              </template>
            </el-table-column>

            <el-table-column
              prop="item_no"
              align="center"
              label="商品类型"
              width="200"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.b_type === 3">国内饰品</span>
                <table v-else style="width: 198px">
                  <tr>
                    <td>编码：</td>
                    <td align="left">
                      <el-tag type="warning">{{ scope.row.gt_cls_no }}</el-tag>
                    </td>
                  </tr>
                  <tr>
                    <td>大类：</td>
                    <td align="left">
                      <el-tag type="danger"
                      >{{ scope.row.BTypeClsCn }}
                      </el-tag>
                    </td>
                  </tr>
                  <tr>
                    <td>中类：</td>
                    <td align="left">
                      <el-tag type="success"
                      >{{ scope.row.MTypeClsCn }}
                      </el-tag>
                    </td>
                  </tr>
                  <tr>
                    <td>小类：</td>
                    <td align="left">
                      <el-tag type="info"
                      >{{ scope.row.GtClsNoClsCn }}
                      </el-tag>
                    </td>
                  </tr>
                </table>
              </template>
            </el-table-column>

            <el-table-column width="100" align="center" label="价格">
              <template slot-scope="scope">
                <el-row :gutter="5">
                  <el-col :span="10" class="endalign fontsize15">
                    <span>进:</span>
                  </el-col>
                  <el-col :span="14" class="leftalign fontsize15">
                    {{ scope.row.base_price? scope.row.base_price.toFixed(2):"" }}
                  </el-col>
                </el-row>
                <el-row :gutter="5">
                  <el-col :span="10" class="endalign fontsize15">
                    <span>售:</span>
                  </el-col>
                  <el-col :span="14" class="leftalign fontsize15">
                    {{ scope.row.sale_price? scope.row.sale_price.toFixed(2):"" }}
                  </el-col>
                </el-row>
<!--                <el-row>
                  <span v-if="scope.row.sale_price > scope.row.base_price" style="color: red">+ {{ (scope.row.sale_price * 1000 - scope.row.base_price * 1000)/1000 }}</span>
                  <span v-if="scope.row.sale_price < scope.row.base_price" style="color: green">- {{ (scope.row.base_price * 1000 - scope.row.sale_price * 1000)/1000 }}</span>
                </el-row>-->
              </template>
            </el-table-column>

            <el-table-column
              prop="stock_num"
              align="center"
              width="150"
              label="产品库存"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-row :gutter="5">
                  <el-col :span="16" class="endalign fontsize15">
                    <span>海鼎库存:</span>
                  </el-col>
                  <el-col :span="8" class="leftalign fontsize15">
                    <span class="text-color">{{ scope.row.stock_num }}</span>
                  </el-col>
                </el-row>
                <el-row :gutter="5">
                  <el-col :span="16" class="endalign fontsize15">
                    <span>购物车占用:</span>
                  </el-col>
                  <el-col :span="8" class="leftalign fontsize15">
                    <span class="text-color">{{ scope.row.carts_num }}</span>
                  </el-col>
                </el-row>
                <el-row :gutter="5">
                  <el-col :span="16" class="endalign fontsize15">
                    <span>订单占用:</span>
                  </el-col>
                  <el-col :span="8" class="leftalign fontsize15">
                    <span class="text-color">{{ scope.row.order_num }}</span>
                  </el-col>
                </el-row>
                <el-row :gutter="5">
                  <el-col :span="16" class="endalign fontsize15">
                    <span>可用库存:</span>
                  </el-col>
                  <el-col :span="8" class="leftalign fontsize15">
                    <span class="text-color">{{ scope.row.us_num }}</span>
                  </el-col>
                </el-row>
                <!-- 添加未到量 -->
                <el-row :gutter="5">
                  <el-col :span="16" class="endalign fontsize15">
                    <span>未到量:</span>
                  </el-col>
                  <el-col :span="8" class="leftalign fontsize15">
                    <span class="text-color">{{ scope.row.unarrived_stock_num }}</span>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>

            <el-table-column label="下架原因" align="center" prop="off_shelf_reason"></el-table-column>

            <el-table-column
              label="设置"
              width="180"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div>
                  <el-switch
                    v-model="scope.row.display_flag"
                    @change="changeUp($event, scope.row, 'line')"
                    active-text="上架"
                    inactive-text="下架"
                    :active-value="1"
                    :inactive-value="0"
                  >
                  </el-switch>
                </div>

                <div>
                  <el-switch
                    v-model="scope.row.heat"
                    @change="changeUp($event, scope.row, 'hot')"
                    active-text="热门"
                    inactive-text="常规"
                    :active-value="1"
                    :inactive-value="0"
                  >
                  </el-switch>
                </div>
                <!-- 首次上架时间 -->
                <div>
                  首次上架时间：<br><span>{{ scope.row.first_display_flag_time?new Date(scope.row.first_display_flag_time*1000).toLocaleString("zh-Hans-CN",{year:'numeric',month:'2-digit',day:'2-digit',hour:'2-digit', minute:'2-digit', second:'2-digit'}).replace(/\//g,'-'):"" }}</span>
                </div>
                <div v-show="index === 2">
                  <el-switch
                    v-model="scope.row.in_lang"
                    @change="changeUp($event, scope.row, 'in_lang')"
                    active-text="国际仓"
                    inactive-text="国内仓"
                    :active-value="0"
                    :inactive-value="1"
                  >
                  </el-switch>
                </div>
              </template>
            </el-table-column>

            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-button-group>
                  <el-button
                    type="danger"
                    size="mini"
                    style="margin: 5px"
                    @click="editGoods(scope.row)"
                  >编辑
                  </el-button>
                  <el-button type="infor" size="mini" style="margin: 5px">查看</el-button>
                </el-button-group>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="footer">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="tableData[index].currentPage"
            :page-sizes="[10, 20, 50, 100, 500, 1000]"
            :page-size="tableData[index].pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData[index].total"
            background
          >
          </el-pagination>
        </div>
      </el-tab-pane>

    </el-tabs>

    <el-drawer
      title="编辑产品"
      :with-header="false"
      :visible.sync="showEditDiv"
      size="55%"
      direction="rtl"
      :before-close="handleClose"
    >
      <div class="editDivBase">
        <span class="editGoodsTitle">编辑产品</span>
        <span class="editGoodsContent">
          <editGoods :code="showEditCode"></editGoods>
        </span>
        <span class="editGoodsfooter">
          <el-button
            type="danger"
            style="margin-left:90%"
            @click="showEditDiv = false"
          >关闭</el-button>
        </span>
      </div>
    </el-drawer>

    <!-- 更新条码  -->
    <el-dialog
      title="更新条码"
      :visible.sync="upSkyObj.hasUpSku"
      width="30%"
    >
      <span>
        <span style="color: red"
        >（*系统会按这个时间之后有更新过的产品进行更新）</span
        ><br />
        <el-date-picker
          v-model="upSkyObj.upSkuTime"
          align="right"
          type="date"
          placeholder="选择某个日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="upSkyObj.hasUpSku = false">取 消</el-button>
        <el-button type="primary" @click="upSkuSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="批量禁配" :visible.sync="showbanwrap" width="35%" :close-on-click-modal="false">
      <div class="addAdmin">
        <p class="addAdmin_CharacterSet">商品条码：</p>
          <el-input type="textarea" rows="8" v-model="dryData.skus"></el-input>
      </div>
      <!--  -->
      <div class="addAdmin">
        <p class="addAdmin_CharacterSet">禁配地区：</p>
        <div class="addAdmin_select">
          <el-select
            v-model="dryData.value"
            filterable
            placeholder="请选择"
            multiple
            style="width: 80%"
          >
            <el-option
              v-for="item in dryData.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="addAdmin">
        <p class="addAdmin_CharacterSet">禁配备注：</p>
        <div class="addAdmin_select">
          <el-input type="textarea" :rows="4" v-model="dryData.ban_remark"></el-input>
        </div>
      </div>

      <div class="addAdmin">
        <p class="addAdmin_CharacterSet">快速选择：</p>
        <div class="addAdmin_select">
          <el-checkbox-group v-model="checkboxGroupSel" size="small">
            <el-checkbox
              label="欧盟"
              border
              @change="(val) => changeCountry(val, '欧盟')"
            ></el-checkbox>
            <el-checkbox
              label="伊斯兰教"
              border
              @change="(val) => changeCountry(val, '伊斯兰教')"
            ></el-checkbox>
            <el-checkbox
              label="海湾"
              border
              @change="(val) => changeCountry(val, '海湾')"
            ></el-checkbox>
            <el-checkbox
              label="东盟"
              border
              @change="(val) => changeCountry(val, '东盟')"
            ></el-checkbox>
            <el-checkbox
              label="全部"
              border
              @change="(val) => changeCountry(val, '全部')"
            ></el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div class="addAdmin">
        <p class="addAdmin_CharacterSet"></p>
        <div class="addAdmin_select">
          <el-button
            type="primary"
            @click="configmSubmit"
          >修 改</el-button>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import goodsManager from "./js/index";

export default goodsManager
</script>

<style scoped>
.footer {
  margin-top: 20px;
}

.editDivBase {
  height: 100vh;
}

.editDivBase {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.editGoodsContent {
  flex: 1;
  overflow: auto;
  overflow-y: scroll;
}

.editGoodsTitle {
  height: 5%;
  line-height: 50px;
  text-indent: 1em;
  background: #218da0;
  color: white;
}

.editGoodsfooter {
  height: 45px;
  line-height: 45px;
  border-top: 1px solid #c0ccda;
}

/* 国内仓的商品 */
</style>
<style lang="scss" scoped>
.dowExcelFile {
  font-size: 14px;
  color: cadetblue;
}

.cnGoodsText {
  border: 1px solid #f56c6c;
  margin: 10px 0;

  &_title {
    height: 35px;
    line-height: 35px;
    text-indent: 1em;
    background: #f56c6c;
    color: white;
  }
}

.text-color {
  color: #409EFF;
}

.form-wrap {
  display: flex;
  align-items: center;

  .form-item-wrap {
    margin-bottom: 0;
  }
}

.fontsize15{
  font-size: 15px;
}
.endalign{
  text-align: end;
}
.leftalign{
  text-align: left;
}

// 重新设置搜索框样式@connor
::v-deep{
  .el-form-item__label{
    width: auto !important;
  }
  .buttonSearchDiv > div:first-child{
    display: inline-block !important;
  }
  .buttonSearchDiv > div:first-child >form{
      display: inline-block !important;
  }
}
</style>
